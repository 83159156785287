import { MAX_POZNAMKA_LENGTH } from '../../../utils/enums'

const validate = (values, props) => {
	const errors = {}

	if (values?.ukonPoznamka && values?.ukonPoznamka?.length > MAX_POZNAMKA_LENGTH) {
		errors.ukonPoznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je {maxDlzka} znakov', { maxDlzka: MAX_POZNAMKA_LENGTH })
	}
	return errors
}

export default validate
