import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

function UkonVseobecnyAnonymny(props) {
	const { t, ukonData, formatDocuments } = props
	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Kategória 1')}</strong>
				</td>
				<td>{get(ukonData, 'ukonKategoria1.nazov', '-')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Kategória 2')}</strong>
				</td>
				<td>{get(ukonData, 'ukonKategoria2.nazov', '-')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Kategória 3')}</strong>
				</td>
				<td>{get(ukonData, 'ukonKategoria3.nazov', '-')}</td>
			</tr>
			<tr>
				<td colSpan={2}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonVseobecnyAnonymny.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonVseobecnyAnonymny
