import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { formatDate } from '../../utils/date'
import { celeMeno } from '../../utils/obchodnyPartner'

class UkonSplnomocnenie extends React.Component {
	render() {
		const { t, ukonData, formatDocuments } = this.props

		return (
			<tbody>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Číslo obchodného partnera')}</strong>
					</td>
					<td>{get(ukonData, 'splnomocnenec.cislo')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Splnomocnená osoba')}</strong>
					</td>
					<td>{celeMeno(get(ukonData, 'splnomocnenec'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Platnosť od')}</strong>
					</td>
					<td>{formatDate(get(ukonData, 'platnostOd'), null, 'DD.MM.YYYY')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Platnosť do')}</strong>
					</td>
					<td>{formatDate(get(ukonData, 'platnostDo'), null, 'DD.MM.YYYY')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Rozsah splnomocnenia')}</strong>
					</td>
					<td>{get(ukonData, 'splnomocnenieRozsah')}</td>
				</tr>
				<tr>
					<td colSpan={2}>{formatDocuments}</td>
				</tr>
			</tbody>
		)
	}
}

UkonSplnomocnenie.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired,
	obchodnyPartnerActions: PropTypes.shape().isRequired
}

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(connect(null, mapDispatchToProps))(UkonSplnomocnenie)
