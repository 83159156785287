import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { formatFormValue } from '../../utils/form'

function UkonZmenaUdajovMS(props) {
	const { t, ukonData, ukonDataPred, formatDocuments } = props
	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonZmenaUdajovMS.Číslo miesta spotreby')}</strong>
				</td>
				<td>{get(ukonDataPred, 'msCislo')}</td>
				<td>{formatFormValue(get(ukonData, 'msCislo'), get(ukonDataPred, 'msCislo'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonZmenaUdajovMS.Druh miesta spotreby')}</strong>
				</td>
				<td>{get(ukonDataPred, 'msDruh.nazov')}</td>
				<td>{formatFormValue(get(ukonData, 'msDruh.nazov'), get(ukonDataPred, 'msDruh.nazov'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonZmenaUdajovMS.Poschodie')}</strong>
				</td>
				<td>{get(ukonDataPred, 'msPoschodie')}</td>
				<td>{formatFormValue(get(ukonData, 'msPoschodie'), get(ukonDataPred, 'msPoschodie'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonZmenaUdajovMS.Číslo miestnosti')}</strong>
				</td>
				<td>{get(ukonDataPred, 'msBytCislo')}</td>
				<td>{formatFormValue(get(ukonData, 'msBytCislo'), get(ukonDataPred, 'msBytCislo'))}</td>
			</tr>
			<tr>
				<td colSpan={3}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonZmenaUdajovMS.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonZmenaUdajovMS
