import React from 'react'
import { get, head, isEmpty, map, includes, find, upperFirst } from 'lodash'
import PropTypes from 'prop-types'
import * as IBAN from 'iban'

// utils
import { formatOriginalSuhlas, formatSuhlas } from '../../utils/suhlas'
import { formatFormValue } from '../../utils/form'
import { formatAddress } from '../../utils/address'
import { ZMLUVNY_UCET_TYP, SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA } from '../../utils/enums'

function UkonZmenaUdajovZU(props) {
	const { t, ukonData, ukonDataPred, formatDocuments, cesCiselniky } = props

	const ukonDataZU = head(get(ukonData, 'zmluvneUcty', []))
	const ukonDataPredZU = find(get(ukonDataPred, 'zmluvneUcty', []), { cislo: get(ukonDataZU, 'cislo') })

	const isNCB = get(ukonDataZU, 'typ.id') == ZMLUVNY_UCET_TYP.NCB

	const eFakturaEmailyPredValues = map(get(ukonDataPredZU, 'eFakturaEmaily', []), (item) => get(item, 'email'))
	const eFakturaEmailyPred = map(get(ukonDataPredZU, 'eFakturaEmaily', []), (item, index) => <p key={index}>{get(item, 'email')}</p>)
	const eFakturaEmaily = map(get(ukonDataZU, 'eFakturaEmaily', []), (item, index) => {
		if (!includes(eFakturaEmailyPredValues, get(item, 'email'))) {
			return (
				<p key={index}>
					<strong>{get(item, 'email')}</strong>
				</p>
			)
		}
		return <p key={index}>{get(item, 'email')}</p>
	})

	let eFakturaEmailySection = eFakturaEmaily
	if (isEmpty(eFakturaEmaily)) {
		if (!isEmpty(eFakturaEmailyPred)) {
			eFakturaEmailySection = <strong>{t('translation:Common.neaktívna')}</strong>
		} else {
			eFakturaEmailySection = t('translation:Common.neaktívna')
		}
	}

	const eFakturaOriginalSuhlas = get(ukonDataPredZU, 'suhlasEfaktura')
	const eFakturaNewSuhlas = get(ukonDataZU, 'suhlasEfaktura')
	const eFakturaOriginalNonPreferredValue = isEmpty(get(ukonDataPredZU, 'eFakturaEmaily', []))
	const eFakturaNewNonPreferredValue = isEmpty(get(ukonDataZU, 'eFakturaEmaily', []))

	const doslePlatbyOriginalSuhlas = get(ukonDataPredZU, 'suhlasEplatba')
	const doslePlatbyNewSuhlas = get(ukonDataZU, 'suhlasEplatba')
	const doslePlatbyOriginalNonPreferredValue = get(ukonDataPredZU, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA
	const doslePlatbyNewNonPreferredValue = get(ukonDataZU, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA

	const odoslanePlatbyOriginalSuhlas = get(ukonDataPredZU, 'suhlasEpreplatky')
	const odoslanePlatbyNewSuhlas = get(ukonDataZU, 'suhlasEpreplatky')
	const odoslanePlatbyOriginalNonPreferredValue = get(ukonDataPredZU, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA
	const odoslanePlatbyNewNonPreferredValue = get(ukonDataZU, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA

	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('translation:ZmluvneUcty.Číslo zmluvného účtu')}</strong>
				</td>
				<td>{get(ukonDataPredZU, 'cislo')}</td>
				<td>{formatFormValue(get(ukonDataZU, 'cislo'), get(ukonDataPredZU, 'cislo'))}</td>
			</tr>
			{isNCB && (
				<tr>
					<td>
						<strong>{t('translation:ZmluvneUcty.Frekvencia platby')}</strong>
					</td>
					<td>{upperFirst(get(ukonDataPredZU, 'fakturacnaPerioda.nazov'))}</td>
					<td>
						{formatFormValue(
							get(ukonDataZU, 'fakturacnaPerioda.nazov'),
							get(ukonDataPredZU, 'fakturacnaPerioda.nazov'),
							upperFirst(get(ukonDataZU, 'fakturacnaPerioda.nazov'))
						)}
					</td>
				</tr>
			)}
			<tr>
				<td>
					<strong>{t('translation:ZmluvneUcty.E-mailové adresy pre e-faktúry')}</strong>
				</td>
				<td>
					{!isEmpty(eFakturaEmailyPred) ? eFakturaEmailyPred : t('translation:Common.neaktívna')}
					<p>{formatOriginalSuhlas(eFakturaOriginalNonPreferredValue, eFakturaOriginalSuhlas)}</p>
				</td>
				<td>
					{eFakturaEmailySection}
					<p>
						{eFakturaNewNonPreferredValue &&
							formatSuhlas(eFakturaNewNonPreferredValue, eFakturaNewSuhlas, eFakturaOriginalSuhlas, cesCiselniky.nesuhlasDovod)}
					</p>
				</td>
			</tr>
			<tr>
				<td colSpan='3'>
					<div className='inner-box'>
						<h4 className='uppercase' data-text-color='blue' style={{ margin: '10px' }}>
							{t('translation:ZmluvneUcty.Prijaté platby')}
						</h4>
						<table className='content-table padded bordered' cellSpacing='0'>
							<tbody>
								<tr>
									<td>
										<strong>{t('translation:ZmluvneUcty.Spôsob platby zmluvného účtu')}</strong>
									</td>
									<td>
										{upperFirst(get(ukonDataPredZU, 'doslePlatbySposob.nazov'))}
										<p>{formatOriginalSuhlas(doslePlatbyOriginalNonPreferredValue, doslePlatbyOriginalSuhlas)}</p>
									</td>
									<td>
										{formatFormValue(
											get(ukonDataZU, 'doslePlatbySposob.id'),
											get(ukonDataPredZU, 'doslePlatbySposob.id'),
											upperFirst(get(ukonDataZU, 'doslePlatbySposob.nazov'))
										)}
										<p>
											{doslePlatbyNewNonPreferredValue &&
												formatSuhlas(
													doslePlatbyNewNonPreferredValue,
													doslePlatbyNewSuhlas,
													doslePlatbyOriginalSuhlas,
													cesCiselniky.nesuhlasDovod
												)}
										</p>
									</td>
								</tr>
								{(get(ukonDataZU, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA ||
									get(ukonDataPredZU, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) && (
									<tr>
										<td>
											<strong>{t('translation:ZmluvneUcty.Adresa pre prijaté platby')}</strong>
										</td>
										<td>{formatAddress(get(ukonDataPredZU, 'adresaDoslePlatby'))}</td>
										<td>
											{formatFormValue(
												formatAddress(get(ukonDataZU, 'adresaDoslePlatby')),
												formatAddress(get(ukonDataPredZU, 'adresaDoslePlatby'))
											)}
										</td>
									</tr>
								)}
								{(get(ukonDataZU, 'doslePlatbySposob.id') != SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA ||
									get(ukonDataPredZU, 'doslePlatbySposob.id') != SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) && (
									<tr>
										<td>
											<strong>{t('translation:ZmluvneUcty.IBAN')}</strong>
										</td>
										<td>{IBAN.printFormat(get(ukonDataPredZU, 'doslePlatbyBankoveSpojenie.id') || '')}</td>
										<td>
											{formatFormValue(
												get(ukonDataZU, 'doslePlatbyBankoveSpojenie.id'),
												get(ukonDataPredZU, 'doslePlatbyBankoveSpojenie.id'),
												IBAN.printFormat(get(ukonDataZU, 'doslePlatbyBankoveSpojenie.id') || '')
											)}
										</td>
									</tr>
								)}
								{(get(ukonDataPredZU, 'sepaMandat.cislo') || get(ukonDataZU, 'sepaMandat.cislo')) && (
									<tr>
										<td>
											<strong>{t('translation:ZmluvneUcty.Číslo SEPA mandátu')}</strong>
										</td>
										<td>{get(ukonDataPredZU, 'sepaMandat.cislo') || ''}</td>
										<td>
											{formatFormValue(
												get(ukonDataZU, 'sepaMandat.cislo'),
												get(ukonDataPredZU, 'sepaMandat.cislo'),
												get(ukonDataZU, 'sepaMandat.cislo') || ''
											)}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</td>
			</tr>
			<tr>
				<td colSpan='3'>
					<div className='inner-box'>
						<h4 className='uppercase' data-text-color='blue' style={{ margin: '10px' }}>
							{t('translation:ZmluvneUcty.Odoslané platby')}
						</h4>
						<table className='content-table padded bordered' cellSpacing='0'>
							<tbody>
								<tr>
									<td>
										<strong>{t('translation:ZmluvneUcty.Spôsob platby zmluvného účtu')}</strong>
									</td>
									<td>
										{upperFirst(get(ukonDataPredZU, 'odoslanePlatbySposob.nazov'))}
										<p>{formatOriginalSuhlas(odoslanePlatbyOriginalNonPreferredValue, odoslanePlatbyOriginalSuhlas)}</p>
									</td>
									<td>
										{formatFormValue(
											get(ukonDataZU, 'odoslanePlatbySposob.id'),
											get(ukonDataPredZU, 'odoslanePlatbySposob.id'),
											upperFirst(get(ukonDataZU, 'odoslanePlatbySposob.nazov'))
										)}
										<p>
											{odoslanePlatbyNewNonPreferredValue &&
												formatSuhlas(
													odoslanePlatbyNewNonPreferredValue,
													odoslanePlatbyNewSuhlas,
													odoslanePlatbyOriginalSuhlas,
													cesCiselniky.nesuhlasDovod
												)}
										</p>
									</td>
								</tr>
								{(get(ukonDataZU, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA ||
									get(ukonDataPredZU, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA) && (
									<tr>
										<td>
											<strong>{t('translation:ZmluvneUcty.Adresa pre odoslané platby')}</strong>
										</td>
										<td>{formatAddress(get(ukonDataPredZU, 'adresaOdoslanePlatby'))}</td>
										<td>
											{formatFormValue(
												formatAddress(get(ukonDataZU, 'adresaOdoslanePlatby')),
												formatAddress(get(ukonDataPredZU, 'adresaOdoslanePlatby'))
											)}
										</td>
									</tr>
								)}
								{(get(ukonDataZU, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ ||
									get(ukonDataPredZU, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ) && (
									<tr>
										<td>
											<strong>{t('translation:ZmluvneUcty.IBAN')}</strong>
										</td>
										<td>{IBAN.printFormat(get(ukonDataPredZU, 'odoslanePlatbyBankoveSpojenie.id') || '')}</td>
										<td>
											{formatFormValue(
												get(ukonDataZU, 'odoslanePlatbyBankoveSpojenie.id'),
												get(ukonDataPredZU, 'odoslanePlatbyBankoveSpojenie.id'),
												IBAN.printFormat(get(ukonDataZU, 'odoslanePlatbyBankoveSpojenie.id') || '')
											)}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:ZmluvneUcty.Fakturačná adresa')}</strong>
					{(get(ukonDataZU, 'adresaFakturacie.doRukMeno') || get(ukonDataPredZU, 'adresaFakturacie.doRukMeno')) && (
						<>
							<br />
							<strong>{t('translation:Common.Do rúk')}</strong>
						</>
					)}
				</td>
				<td>
					{formatAddress(get(ukonDataPredZU, 'adresaFakturacie'), false)}
					{get(ukonDataPredZU, 'adresaFakturacie.doRukMeno') && (
						<>
							<br />
							<div>{get(ukonDataPredZU, 'adresaFakturacie.doRukMeno')}&nbsp;</div>
						</>
					)}
				</td>
				<td>
					{formatFormValue(formatAddress(get(ukonDataZU, 'adresaFakturacie'), false), formatAddress(get(ukonDataPredZU, 'adresaFakturacie'), false))}
					{formatFormValue(get(ukonDataZU, 'adresaFakturacie.doRukMeno'), get(ukonDataPredZU, 'adresaFakturacie.doRukMeno')) && (
						<>
							<br />
							<div>{formatFormValue(get(ukonDataZU, 'adresaFakturacie.doRukMeno'), get(ukonDataPredZU, 'adresaFakturacie.doRukMeno'))}&nbsp;</div>
						</>
					)}
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:ZmluvneUcty.Adresa pre doručovanie poštových poukážok')}</strong>
					{(get(ukonDataZU, 'adresaOdoslanePlatby.doRukMeno') || get(ukonDataPredZU, 'adresaOdoslanePlatby.doRukMeno')) && (
						<>
							<br />
							<strong>{t('translation:Common.Do rúk')}</strong>
						</>
					)}
				</td>
				<td>
					{formatAddress(get(ukonDataPredZU, 'adresaOdoslanePlatby'), false)}
					{get(ukonDataPredZU, 'adresaOdoslanePlatby.doRukMeno') && (
						<>
							<br />
							<div>{get(ukonDataPredZU, 'adresaOdoslanePlatby.doRukMeno')}&nbsp;</div>
						</>
					)}
				</td>
				<td>
					{formatFormValue(
						formatAddress(get(ukonDataZU, 'adresaOdoslanePlatby'), false),
						formatAddress(get(ukonDataPredZU, 'adresaOdoslanePlatby'), false)
					)}
					{formatFormValue(get(ukonDataZU, 'adresaOdoslanePlatby.doRukMeno'), get(ukonDataPredZU, 'adresaOdoslanePlatby.doRukMeno')) && (
						<>
							<br />
							<div>
								{formatFormValue(get(ukonDataZU, 'adresaOdoslanePlatby.doRukMeno'), get(ukonDataPredZU, 'adresaOdoslanePlatby.doRukMeno'))}
								&nbsp;
							</div>
						</>
					)}
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:ZmluvneUcty.Adresa pre zasielanie upomienok')}</strong>
					{(get(ukonDataZU, 'adresaUpominacia.doRukMeno') || get(ukonDataPredZU, 'adresaUpominacia.doRukMeno')) && (
						<>
							<br />
							<strong>{t('translation:Common.Do rúk')}</strong>
						</>
					)}
				</td>
				<td>
					{formatAddress(get(ukonDataPredZU, 'adresaUpominacia'), false)}
					{get(ukonDataPredZU, 'adresaUpominacia.doRukMeno') && (
						<>
							<br />
							<div>{get(ukonDataPredZU, 'adresaUpominacia.doRukMeno')}&nbsp;</div>
						</>
					)}
				</td>
				<td>
					{formatFormValue(formatAddress(get(ukonDataZU, 'adresaUpominacia'), false), formatAddress(get(ukonDataPredZU, 'adresaUpominacia'), false))}
					{formatFormValue(get(ukonDataZU, 'adresaUpominacia.doRukMeno'), get(ukonDataPredZU, 'adresaUpominacia.doRukMeno')) && (
						<>
							<br />
							<div>{formatFormValue(get(ukonDataZU, 'adresaUpominacia.doRukMeno'), get(ukonDataPredZU, 'adresaUpominacia.doRukMeno'))}&nbsp;</div>
						</>
					)}
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:ZmluvneUcty.Pripomienka platby')}</strong>
				</td>
				<td>{get(ukonDataPredZU, 'neposielatPripomienkuPlatby') ? t('translation:Common.Neaktívne') : t('translation:Common.Aktívne')}</td>
				<td>
					{formatFormValue(
						get(ukonDataZU, 'neposielatPripomienkuPlatby') ? t('translation:Common.Neaktívne') : t('translation:Common.Aktívne'),
						get(ukonDataPredZU, 'neposielatPripomienkuPlatby') ? t('translation:Common.Neaktívne') : t('translation:Common.Aktívne')
					)}
				</td>
			</tr>
			<tr>
				<td colSpan={3}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonZmenaUdajovZU.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired,
	cesCiselniky: PropTypes.shape()
}

export default UkonZmenaUdajovZU
