import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { formatFormValue } from '../../utils/form'
import { formatAddress } from '../../utils/address'

function UkonZmenaUdajovZU(props) {
	const { t, ukonData, ukonDataPred, formatDocuments } = props

	const adresaUpominacia = formatAddress(get(ukonData, 'adresaUpominacia', null))
	const adresaUpominaciaPred = formatAddress(get(ukonDataPred, 'adresaUpominacia', null))

	const adresaOdoslanePlatby = formatAddress(get(ukonData, 'adresaOdoslanePlatby', null))
	const adresaOdoslanePlatbyPred = formatAddress(get(ukonDataPred, 'adresaOdoslanePlatby', null))

	const adresaFakturacie = formatAddress(get(ukonData, 'adresaFakturacie', null))
	const adresaFakturaciePred = formatAddress(get(ukonDataPred, 'adresaFakturacie', null))

	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Číslo odlišného príjemcu platcu')}</strong>
				</td>
				<td>{get(ukonDataPred, 'odlisnyPrijemcaPlatca')}</td>
				<td>{formatFormValue(get(ukonData, 'odlisnyPrijemcaPlatca'), get(ukonDataPred, 'odlisnyPrijemcaPlatca'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Číslo zmluvného účtu')}</strong>
				</td>
				<td>{get(ukonDataPred, 'zmluvnyUcetCislo')}</td>
				<td>{formatFormValue(get(ukonData, 'zmluvnyUcetCislo'), get(ukonDataPred, 'zmluvnyUcetCislo'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Adresa pre zasielanie upomienok')}</strong>
					<br />
					<strong>{t('containers:UkonDetailPage.Do rúk')}</strong>
				</td>
				<td>
					{adresaUpominaciaPred}
					<br />
					<div>{get(ukonDataPred, 'adresaUpominacia.doRukMeno')}&nbsp;</div>
				</td>
				<td>
					{formatFormValue(adresaUpominacia, adresaUpominaciaPred)}
					<br />
					<div>{formatFormValue(get(ukonData, 'adresaUpominacia.doRukMeno'), get(ukonDataPred, 'adresaUpominacia.doRukMeno'))}&nbsp;</div>
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Adresa pre doručovanie poštových poukážok')}</strong>
					<br />
					<strong>{t('containers:UkonDetailPage.Do rúk')}</strong>
				</td>
				<td>
					{adresaOdoslanePlatbyPred}
					<br />
					<div>{get(ukonDataPred, 'adresaOdoslanePlatby.doRukMeno')}&nbsp;</div>
				</td>
				<td>
					{formatFormValue(adresaOdoslanePlatby, adresaOdoslanePlatbyPred)}
					<br />
					<div>{formatFormValue(get(ukonData, 'adresaOdoslanePlatby.doRukMeno'), get(ukonDataPred, 'adresaOdoslanePlatby.doRukMeno'))}&nbsp;</div>
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Fakturačná adresa')}</strong>
					<br />
					<strong>{t('containers:UkonDetailPage.Do rúk')}</strong>
				</td>
				<td>
					{adresaFakturaciePred}
					<br />
					<div>{get(ukonDataPred, 'adresaOdoslanePlatby.doRukMeno')}&nbsp;</div>
				</td>
				<td>
					{formatFormValue(adresaFakturacie, adresaFakturaciePred)}
					<br />
					<div>{formatFormValue(get(ukonData, 'adresaFakturacie.doRukMeno'), get(ukonDataPred, 'adresaFakturacie.doRukMeno'))}&nbsp;</div>
				</td>
			</tr>
			<tr>
				<td colSpan={3}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonZmenaUdajovZU.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonZmenaUdajovZU
