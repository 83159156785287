import React from 'react'
import { get, map, isEmpty, sumBy } from 'lodash'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { POHLADAVKA_TYP } from '../../utils/enums'
import { getTypPohladavkyTooltip } from '../../utils/pohladavky'
import { formatDate } from '../../utils/date'

class UkonVrateniePreplatkov extends React.Component {
	static propTypes = {
		ukon: PropTypes.shape(),
		ukonData: PropTypes.shape(),
		formatDocuments: PropTypes.func.isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired
	}

	render() {
		const { ukonData, formatDocuments, t } = this.props

		return (
			<tbody>
				<tr>
					<td>
						<strong>{t('translation:Common.Zmluvný účet')}</strong>
					</td>
					<td>{get(ukonData, 'zuCislo')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:Preplatky.Preplatky')}</strong>
					</td>
					<td style={{ padding: 0 }}>
						<table className='search-results-table no-pointer' cellSpacing='0' style={{ border: 'none' }}>
							<thead>
								<tr>
									<th style={{ width: '20%' }}>{t('translation:Preplatky.filter.Číslo faktúry')}</th>
									<th style={{ width: '40%' }}>{t('translation:Preplatky.filter.Typ')}</th>
									<th style={{ width: '20%' }}>{t('translation:Preplatky.filter.Číslo dokladu')}</th>
									<th style={{ whiteSpace: 'nowrap', textAlign: 'right', width: '20%' }}>
										{t('translation:Preplatky.filter.Otvorená suma')}
									</th>
								</tr>
							</thead>
							<tbody data-empty={t('translation:Preplatky.Neboli zvolené žiadne preplatky')}>
								{map(get(ukonData, 'preplatky', []), (pohladavka, index) => {
									let typPohladavky
									switch (get(pohladavka, 'typ.id')) {
										case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
										case POHLADAVKA_TYP.AKONTO.value:
										case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
										case POHLADAVKA_TYP.UDRZBA_UCTU.value:
										case POHLADAVKA_TYP.STANDARD_KVALITY.value:
											typPohladavky = (
												<Tooltip html={getTypPohladavkyTooltip(pohladavka, t)} position='top' trigger='mouseenter' theme='light'>
													{get(pohladavka, 'typ.nazov')}
												</Tooltip>
											)
											break
										default:
											typPohladavky = get(pohladavka, 'typ.nazov')
									}

									const sumaOtvorena = (
										<NumericFormat
											thousandSeparator={' '}
											decimalSeparator={','}
											decimalScale={2}
											fixedDecimalScale={true}
											displayType='text'
											suffix=' €'
											value={get(pohladavka, 'sumaOtvorena')}
										/>
									)
									const sumaOtvorenaString = sumaOtvorena

									return (
										<tr key={`pohladavka_${index}`}>
											<td style={{ whiteSpace: 'nowrap' }}>{get(pohladavka, 'variabilnySymbol', '-')}</td>
											<td style={{ wordBreak: 'normal' }}>{typPohladavky}</td>
											<td style={{ whiteSpace: 'nowrap' }}>{get(pohladavka, 'cislo')}</td>
											<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{sumaOtvorenaString}</td>
										</tr>
									)
								})}
								{!isEmpty(get(ukonData, 'preplatky', [])) && (
									<tr key={`pohladavka_spolu`}>
										<td>
											<strong>{t('translation:Common.SPOLU')}</strong>
										</td>
										<td />
										<td />
										<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
											<strong>
												<NumericFormat
													thousandSeparator={' '}
													decimalSeparator={','}
													decimalScale={2}
													fixedDecimalScale={true}
													displayType='text'
													suffix=' €'
													value={sumBy(get(ukonData, 'preplatky', []), 'sumaOtvorena')}
												/>
											</strong>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:Preplatky.Odklad splatnosti')}</strong>
					</td>
					<td>{formatDate(get(ukonData, 'odkladSplatnosti'), null, 'DD.MM.YYYY')}</td>
				</tr>
				<tr>
					<td colSpan={2}>{formatDocuments}</td>
				</tr>
			</tbody>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(connect(null, mapDispatchToProps))(UkonVrateniePreplatkov)
