import React from 'react'
import PropTypes from 'prop-types'

function UkonDuplikatUpomienky(props) {
	const { formatDocuments } = props

	return (
		<tbody>
			<tr>
				<td colSpan={2}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonDuplikatUpomienky.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonDuplikatUpomienky
