import React from 'react'
import { get, upperFirst } from 'lodash'
import PropTypes from 'prop-types'

class UkonSystemoveListy extends React.Component {
	static propTypes = {
		ukon: PropTypes.shape(),
		ukonData: PropTypes.shape(),
		formatDocuments: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	render() {
		const { ukonData, formatDocuments, t } = this.props

		return (
			<tbody>
				<tr>
					<td>
						<strong>{t('translation:Common.Číslo miesta spotreby')}</strong>
					</td>
					<td>{get(ukonData, 'miestoSpotreby.cislo')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:SystemoveListyUkon.Kategória 1')}</strong>
					</td>
					<td>{upperFirst(get(ukonData, 'ukonKategoria1.nazov'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:SystemoveListyUkon.Kategória 2')}</strong>
					</td>
					<td>{upperFirst(get(ukonData, 'ukonKategoria2.nazov'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:SystemoveListyUkon.Kategória 3')}</strong>
					</td>
					<td>{upperFirst(get(ukonData, 'ukonKategoria3.nazov', ''))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:SystemoveListyUkon.Značka')}</strong>
					</td>
					<td>{get(ukonData, 'znacka', '')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:SystemoveListyUkon.Šablóna')}</strong>
					</td>
					<td>{upperFirst(get(ukonData, 'sablona'))}</td>
				</tr>

				<tr>
					<td colSpan={2}>{formatDocuments}</td>
				</tr>
			</tbody>
		)
	}
}

export default UkonSystemoveListy
