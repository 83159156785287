import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

const handleChange = (event, input) => {
	if (isEmpty(event.target.value) || event.target.value === '') {
		input.onChange(null)
	} else {
		input.onChange(event.target.value)
	}
}

function TextareaField({ input, disabled, label, placeholder, required, showLabel, showErrorOnUntouch, meta: { touched, error }, rows }) {
	return (
		<div className='input-wrapper'>
			{showLabel && <label htmlFor={label}>{label}</label>}
			<textarea
				{...input}
				onBlur={(event) => handleChange(event, input)}
				onChange={(event) => handleChange(event, input)}
				disabled={disabled}
				placeholder={placeholder}
				type='text'
				className={cx('form-control', 'input-field', { 'has-error': (touched || showErrorOnUntouch) && error })}
				required={required}
				rows={rows}
			/>
			<div className='text-danger'>{touched || showErrorOnUntouch ? error : ''}</div>
		</div>
	)
}

TextareaField.propTypes = {
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({}).isRequired,
	required: PropTypes.bool,
	rows: PropTypes.string,
	showErrorOnUntouch: PropTypes.bool
}

TextareaField.defaultProps = {
	showLabel: false,
	placeholder: '',
	label: '',
	required: false,
	rows: '3'
}

export default TextareaField
