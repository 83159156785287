import React from 'react'
import { get, map } from 'lodash'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NumericFormat } from 'react-number-format'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { getPlatcaTypString, getPlatcaTypLabel } from '../../utils/planySplatok'
import { formatDate } from '../../utils/date'

class UkonPlanSplatok extends React.Component {
	static propTypes = {
		ukon: PropTypes.shape(),
		ukonData: PropTypes.shape(),
		formatDocuments: PropTypes.func.isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired
	}

	render() {
		const { ukon, ukonData, formatDocuments, t } = this.props

		const platcaComponent =
			[get(ukonData, 'platcaOp.cislo') || '0', '-', get(ukonData, 'platcaOp.meno', ''), get(ukonData, 'platcaOp.priezvisko', '')].join(' ').trim() || null

		return (
			<tbody>
				<tr>
					<td>
						<strong>{t('translation:PlanySplatok.Typ platcu')}</strong>
					</td>
					<td>{getPlatcaTypString(get(ukonData, 'platcaTyp'), t) || '-'}</td>
				</tr>
				{platcaComponent && (
					<tr>
						<td>
							<strong>{getPlatcaTypLabel(get(ukonData, 'platcaTyp'), t) || '-'}</strong>
						</td>
						<td>
							<span>{platcaComponent}</span>
						</td>
					</tr>
				)}
				<tr>
					<td>
						<strong>{t('translation:Common.Zmluvný účet')}</strong>
					</td>
					<td>{get(ukonData, 'zuCislo')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:PlanySplatok.Zahrnuté faktúry')}</strong>
					</td>
					<td style={{ padding: 0 }}>
						<table className='search-results-table no-pointer' cellSpacing='0' style={{ border: 'none' }}>
							<thead>
								<tr>
									<th>{t('translation:Common.Číslo faktúry')}</th>
									<th>{t('translation:Common.Otvorená suma')}</th>
								</tr>
							</thead>
							<tbody data-empty={t('translation:PlanySplatok.Neboli zvolené žiadne faktúry')}>
								{map(get(ukonData, 'zahrnuteFaktury', []), (faktura, index) => (
									<tr key={`faktura_${index}`}>
										<td>{get(faktura, 'cislo', '-')}</td>
										<td style={{ whiteSpace: 'nowrap' }}>
											<NumericFormat
												thousandSeparator={' '}
												decimalSeparator={','}
												decimalScale={2}
												fixedDecimalScale={true}
												displayType='text'
												suffix=' €'
												value={get(faktura, 'sumaOtvorena')}
												defaultValue='-'
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:PlanySplatok.Počet splátok')}</strong>
					</td>
					<td>{get(ukonData, 'splatkyPocet')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:PlanySplatok.Dátum splatnosti 1 splátky (informatívny)')}</strong>
					</td>
					<td>{formatDate(get(ukonData, 'datumSplatnosti'), '', 'DD.MM.YYYY')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:PlanySplatok.Úrok')}</strong>
					</td>
					<td>{get(ukonData, 'urok') ? t('translation:Common.Áno') : t('translation:Common.Nie')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:PlanySplatok.Skontrolované zálohy')}</strong>
					</td>
					<td>{get(ukonData, 'skontrolovaneZalohy') ? t('translation:Common.Áno') : t('translation:Common.Nie')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:Common.Poznámka (interná)')}</strong>
					</td>
					<td
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word'
						}}
					>
						{get(ukon, 'poznamkaInterna')}
					</td>
				</tr>
				<tr>
					<td colSpan={2}>{formatDocuments}</td>
				</tr>
			</tbody>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(connect(null, mapDispatchToProps))(UkonPlanSplatok)
