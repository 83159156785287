import React from 'react'
import { get, upperFirst } from 'lodash'
import PropTypes from 'prop-types'
import * as IBAN from 'iban'

// utils
import { formatFormValue } from '../../utils/form'
import { formatAddress } from '../../utils/address'
import { formatDate } from '../../utils/date'
import { SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA } from '../../utils/enums'

function UkonZmenaSposobuPlatby(props) {
	const { ukon, ukonData, ukonDataPred, formatDocuments, t } = props

	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Číslo zmluvného účtu')}</strong>
				</td>
				<td>{get(ukonDataPred, 'zuCislo')}</td>
				<td>{formatFormValue(get(ukonData, 'zuCislo'), get(ukonDataPred, 'zuCislo'))}</td>
			</tr>
			<tr>
				<td colSpan='3'>
					<div className='inner-box'>
						<h4 className='uppercase' data-text-color='blue' style={{ margin: '10px' }}>
							{t('containers:UkonDetailPage.Prijaté platby')}
						</h4>
						<table className='content-table padded bordered' cellSpacing='0'>
							<tbody>
								<tr>
									<td>
										<strong>{t('containers:UkonDetailPage.Spôsob platby zmluvného účtu')}</strong>
									</td>
									<td>{upperFirst(get(ukonDataPred, 'doslePlatbySposob.nazov', ''))}</td>
									<td>
										{formatFormValue(
											get(ukonData, 'doslePlatbySposob.id'),
											get(ukonDataPred, 'doslePlatbySposob.id'),
											upperFirst(get(ukonData, 'doslePlatbySposob.nazov', ''))
										)}
									</td>
								</tr>
								{(get(ukonData, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA ||
									get(ukonData, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA) && (
									<tr>
										<td>
											<strong>{t('containers:UkonDetailPage.Adresa pre prijaté platby')}</strong>
										</td>
										<td>{formatAddress(get(ukonDataPred, 'adresaDoslePlatby'))}</td>
										<td>
											{formatFormValue(
												formatAddress(get(ukonData, 'adresaDoslePlatby')),
												formatAddress(get(ukonDataPred, 'adresaDoslePlatby'))
											)}
										</td>
									</tr>
								)}
								{get(ukonData, 'doslePlatbySposob.id') != SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA && (
									<tr>
										<td>
											<strong>{t('containers:UkonDetailPage.IBAN')}</strong>
										</td>
										<td>{IBAN.printFormat(get(ukonDataPred, 'doslePlatbyIBAN') || '')}</td>
										<td>
											{formatFormValue(
												get(ukonData, 'doslePlatbyIBAN'),
												get(ukonDataPred, 'doslePlatbyIBAN'),
												IBAN.printFormat(get(ukonData, 'doslePlatbyIBAN') || '')
											)}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</td>
			</tr>
			<tr>
				<td colSpan='3'>
					<div className='inner-box'>
						<h4 className='uppercase' data-text-color='blue' style={{ margin: '10px' }}>
							{t('containers:UkonDetailPage.Odoslané platby')}
						</h4>
						<table className='content-table padded bordered' cellSpacing='0'>
							<tbody>
								<tr>
									<td>
										<strong>{t('containers:UkonDetailPage.Spôsob platby zmluvného účtu')}</strong>
									</td>
									<td>{upperFirst(get(ukonDataPred, 'odoslanePlatbySposob.nazov', ''))}</td>
									<td>
										{formatFormValue(
											get(ukonData, 'odoslanePlatbySposob.id'),
											get(ukonDataPred, 'odoslanePlatbySposob.id'),
											upperFirst(get(ukonData, 'odoslanePlatbySposob.nazov', ''))
										)}
									</td>
								</tr>
								{(get(ukonData, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA ||
									get(ukonData, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) && (
									<tr>
										<td>
											<strong>{t('containers:UkonDetailPage.Adresa pre odoslané platby')}</strong>
										</td>
										<td>{formatAddress(get(ukonDataPred, 'adresaOdoslanePlatby'))}</td>
										<td>
											{formatFormValue(
												formatAddress(get(ukonData, 'adresaOdoslanePlatby')),
												formatAddress(get(ukonDataPred, 'adresaOdoslanePlatby'))
											)}
										</td>
									</tr>
								)}
								{get(ukonData, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ && (
									<tr>
										<td>
											<strong>{t('containers:UkonDetailPage.IBAN')}</strong>
										</td>
										<td>{IBAN.printFormat(get(ukonDataPred, 'odoslanePlatbyIBAN') || '')}</td>
										<td>
											{formatFormValue(
												get(ukonData, 'odoslanePlatbyIBAN'),
												get(ukonDataPred, 'odoslanePlatbyIBAN'),
												IBAN.printFormat(get(ukonData, 'odoslanePlatbyIBAN') || '')
											)}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Fakturačná adresa')}</strong>
					<br />
					<strong>{t('containers:UkonDetailPage.Do rúk')}</strong>
				</td>
				<td>
					{formatAddress(get(ukonDataPred, 'adresaFakturacie', null), false)}
					<br />
					<div>{get(ukonDataPred, 'adresaOdoslanePlatby.doRukMeno')}&nbsp;</div>
				</td>
				<td>
					{formatFormValue(
						formatAddress(get(ukonData, 'adresaFakturacie', null), false),
						formatAddress(get(ukonDataPred, 'adresaFakturacie', null), false)
					)}
					<br />
					<div>{formatFormValue(get(ukonData, 'adresaFakturacie.doRukMeno'), get(ukonDataPred, 'adresaFakturacie.doRukMeno'))}&nbsp;</div>
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Dátum požadovanej zmeny')}</strong>
				</td>
				<td />
				<td>
					<strong>{formatFormValue(formatDate(get(ukon, 'ziadanyOd'), null, 'DD.MM.YYYY'))}</strong>
				</td>
			</tr>
			<tr>
				<td colSpan={3}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonZmenaSposobuPlatby.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	formatDocuments: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
}

export default UkonZmenaSposobuPlatby
