import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { get } from 'lodash'

// components
import Modal from './Modal'

class UkoncenieExternejIntegracie extends React.Component {
	static propTypes = {
		visible: PropTypes.bool,
		onEnd: PropTypes.func,
		onContinue: PropTypes.func,
		onContinueWithoutLinkId: PropTypes.func,
		liveAgentSession: PropTypes.string,
		finesseSession: PropTypes.string,
		call250Session: PropTypes.string,
		t: PropTypes.func.isRequired
	}

	render() {
		const { visible, t, liveAgentSession, finesseSession, call250Session, onEnd, onContinue, onContinueWithoutLinkId } = this.props

		return (
			<Modal size='m' shown={visible}>
				{liveAgentSession && (
					<>
						<div className='modal-header'>
							<h3>{t('components:UkoncenieExternejIntegracie.Prepojenie interakcie na LiveAgent ticket')}</h3>
						</div>
						<div className='modal-content'>
							{t('components:UkoncenieExternejIntegracie.Chcete ukončiť prácu s aktuálnym LiveAgent ticketom?', { liveAgentSession })}
						</div>
					</>
				)}
				{finesseSession && (
					<>
						<div className='modal-header'>
							<h3>{t('components:UkoncenieExternejIntegracie.Prepojenie interakcie s hovorom')}</h3>
						</div>
						<div className='modal-content'>
							{t('components:UkoncenieExternejIntegracie.Chcete ukončiť prácu s aktuálnym hovorom?', { finesseSession })}
						</div>
					</>
				)}
				{call250Session && (
					<>
						<div className='modal-header'>
							<h3>{t('components:UkoncenieExternejIntegracie.Prepojenie interakcie s vyvolaním')}</h3>
						</div>
						<div className='modal-content'>
							{t('components:UkoncenieExternejIntegracie.Chcete ukončiť prácu s aktuálnym vyvolaním?', { call250Session })}
						</div>
					</>
				)}
				<div className='modal-footer clearfix'>
					<button className='button pull-right' onClick={onEnd} data-color='blue'>
						{t('components:UkoncenieExternejIntegracie.Ukončiť')}
					</button>
					<button className='button pull-right' onClick={onContinue} data-type='outline' data-color='blue'>
						{t('components:UkoncenieExternejIntegracie.Pokračovať')}
					</button>
					<button className='button pull-right' onClick={onContinueWithoutLinkId} data-type='outline' data-color='gray'>
						{t('components:UkoncenieExternejIntegracie.Pokračovať bez prepojenia')}
					</button>
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	liveAgentSession: get(state, 'externalIntegrations.liveAgentId'),
	finesseSession: get(state, 'externalIntegrations.finesseId'),
	call250Session: get(state, 'externalIntegrations.call250Id')
})

export default compose(withTranslation('components'), connect(mapStateToProps))(UkoncenieExternejIntegracie)
