import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

function UkonDuplikatSEPAMandatu(props) {
	const { t, ukonData, formatDocuments } = props
	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonPodpisSEPAMandatu.Číslo zmluvného účtu')}</strong>
				</td>
				<td>{get(ukonData, 'zuCislo')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonPodpisSEPAMandatu.Číslo SEPA mandátu')}</strong>
				</td>
				<td>{get(ukonData, 'sepaMandatCislo')}</td>
			</tr>
			{get(ukonData, 'datumPodpisu') && (
				<tr>
					<td>
						<strong>{t('containers:UkonPodpisSEPAMandatu.Dátum podpisu')}</strong>
					</td>
					<td>{get(ukonData, 'datumPodpisu')}</td>
				</tr>
			)}
			<tr>
				<td colSpan={2}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonDuplikatSEPAMandatu.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonDuplikatSEPAMandatu
