import { get, isEmpty, some } from 'lodash'
import dayjs from 'dayjs'
import { BUSINESS_CHANNELS, MAX_POZNAMKA_LENGTH } from '../../../utils/enums'

import { validateZalohyCyklus } from '../../../utils/rozpisyZaloh'

const validate = (values, props) => {
	const errors = {}

	if (values.datumZmeny && values.datumZmeny < dayjs().startOf('day')) {
		errors.datumZmeny = 'Dátum požadovanej zmeny nie je platný - nemôžete vybrať dátum z minulosti.'
	}
	if (values.datumPrijatiaZiadosti) {
		if (
			get(props, 'actualBusinessChannel.id') == BUSINESS_CHANNELS.ZSE_CENTRUM ||
			get(props, 'actualBusinessChannel.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
		) {
			if (values.datumPrijatiaZiadosti < dayjs().startOf('day') || values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = 'Dátum prijatia žiadosti nie je platný - zvoľte prosím aktuálny deň.'
			}
		} else if (get(props, 'actualBusinessChannel.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			if (values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = 'Dátum prijatia žiadosti nie je platný - nemôžete zvoliť dátum v budúcnosti.'
			}
		}
	}

	if (!isEmpty(values.dokumenty)) {
		some(values.dokumenty, (dokument) => {
			if (!dokument.dataAsBase64) {
				errors.dokumenty = props.t('components:EditContactInfo.validate.Neplatný dokument', { nazovDokumentu: dokument.name })
				return true
			}
		})
	}

	if (!values.ukonVstup) {
		errors.ukonVstup = props.t('components:RozpisZalohEditInfo.validate.Vstup do úkonu je povinný')
	}

	if (values.splnomocnenec) {
		if (values.splnomocnenec.length > 120) {
			errors.splnomocnenec = props.t('components:Validate.Splnomocnenec môže mať najviac 120 znakov')
		}
	}

	if (values.podpisMiesto) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = props.t('components:Validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if ((!values.zalohySuma || values.zalohySuma <= 0) && (!values.zalohySumaPlaceholder || values.zalohySumaPlaceholder <= 0)) {
		errors.zalohySuma = props.t('components:Validate.Výška záloh musí byť väčšia ako 0')
	}

	if (values.zalohyCyklus != get(props, 'originalValues.zalohyCyklus.value')) {
		if (!validateZalohyCyklus(values, props)) {
			errors.zalohyCyklus = props.t('components:Validate.Nevyhovujúci počet otvorených plánov záloh')
		}
	}

	if (values.poznamka && values.poznamka.length > MAX_POZNAMKA_LENGTH) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je {maxDlzka} znakov', { maxDlzka: MAX_POZNAMKA_LENGTH })
	}

	return errors
}

export default validate
