import dayjs from 'dayjs'
import { get, isEmpty, some } from 'lodash'

import { BUSINESS_CHANNELS, DOKUMENT_TYP_SPLNOMOCNENIA, MAX_POZNAMKA_LENGTH } from '../../../../utils/enums'

const validate = (values, props) => {
	const errors = {}

	if (!values.ukonVstup) {
		errors.ukonVstup = props.t('components:SplnomocneniaInfo.validate.Vstup do úkonu je povinný')
	}

	if (values.podpisMiesto) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = props.t('components:SplnomocneniaInfo.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if (!values.platnostOd) {
		errors.platnostOd = props.t('components:SplnomocneniaInfo.validate.Dátum platnosti je povinný')
	} else if (
		get(props, 'actualBusinessChannel.id') == BUSINESS_CHANNELS.ZSE_CENTRUM &&
		values.dokumentSplnomocneniaTyp == DOKUMENT_TYP_SPLNOMOCNENIA.VZOROVE_SPLNOMOCNENIE &&
		values.platnostOd < dayjs().startOf('day')
	) {
		errors.platnostOd = props.t('components:SplnomocneniaInfo.validate.Dátum platnosti nie je platný - nemôžete vybrať dátum z minulosti')
	}

	if (values.platnostDo && values.platnostOd && dayjs(values.platnostDo).endOf('day') < dayjs(values.platnostOd).endOf('day')) {
		errors.platnostDo = props.t('components:SplnomocneniaInfo.validate.Dátum platnosti do nie je platný - nemôžete byť skôr ako dátum platnosti od')
	}

	if (values.dokumentSplnomocneniaTyp == DOKUMENT_TYP_SPLNOMOCNENIA.VZOROVE_SPLNOMOCNENIE) {
		if (!values.splnomocnenieRozsah) {
			errors.splnomocnenieRozsah = props.t('components:SplnomocneniaInfo.validate.Rozsah splnomocnenia je povinný')
		}
	} else if (values.dokumentSplnomocneniaTyp == DOKUMENT_TYP_SPLNOMOCNENIA.VLOZIT_SPLNOMOCNENIE) {
		if (isEmpty(values.dokumentSplnomocnenia)) {
			errors.dokumentSplnomocnenia = props.t('components:SplnomocneniaInfo.validate.Dokument splnomocnenia je povinný')
		}
	}

	if (!isEmpty(values.dokumenty)) {
		some(values.dokumenty, (dokument) => {
			if (!dokument.dataAsBase64) {
				errors.dokumenty = props.t('components:ZmluvnyUcetPlatbaEditInfo.validate.Neplatný dokument', { nazovDokumentu: dokument.name })
				return true
			}
		})
	}

	if (values.poznamka && values.poznamka.length > MAX_POZNAMKA_LENGTH) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je {maxDlzka} znakov', { maxDlzka: MAX_POZNAMKA_LENGTH })
	}

	return errors
}

export default validate
