import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import Dropzone from 'react-dropzone'
import PropTypes, { instanceOf } from 'prop-types'
import { compose } from 'redux'
import { map, isEmpty, remove, get, indexOf, orderBy, head } from 'lodash'
import cx from 'classnames'

// components
import DefaultModal from '../components/Modals/DefaultModal'

// utils
import { openDataUriWindow, getBase64FromFile } from '../utils/files'
import { DOKUMENT_TYP } from '../utils/enums'

class DropZoneField extends PureComponent {
	static propTypes = {
		input: PropTypes.shape({
			value: PropTypes.any,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired,
		dokumentTyp: PropTypes.shape({
			id: PropTypes.number.isRequired,
			nazov: PropTypes.string.isRequired
		}),
		canDelete: PropTypes.func,
		placeholder: PropTypes.string,
		t: PropTypes.func.isRequired,
		labelText: PropTypes.string,
		disabled: PropTypes.bool,
		multiple: PropTypes.bool
	}

	constructor(props) {
		super(props)
		this.state = {
			files: [],
			currentUrl: '',
			loading: false,
			showRemoveConfirmModal: null
		}
	}

	componentDidMount() {
		const { input, dokumentTyp } = this.props

		let files = []

		if (isEmpty(dokumentTyp)) {
			files = this.orderFiles([...get(input, 'value', [])])
		} else {
			files = this.orderFiles([...get(input, 'value', [])]?.filter((file) => file?.dokumentTyp?.id === dokumentTyp.id))
		}

		this.setState({
			files
		})
	}

	componentDidUpdate(prevProp) {
		const { input, dokumentTyp } = this.props

		if (get(prevProp, 'input.value.length') !== get(input, 'value.length')) {
			let files = []
			if (isEmpty(dokumentTyp)) {
				files = this.orderFiles([...get(input, 'value', [])])
			} else {
				files = this.orderFiles([...get(input, 'value', [])]?.filter((file) => file?.dokumentTyp?.id === dokumentTyp.id))
			}

			this.setState({
				files
			})
		}
	}

	orderFiles(files) {
		return orderBy(files, ['pridanyOd'], ['desc'])
	}

	removeFile = async (index) => {
		const { input, onDelete } = this.props
		const { files } = this.state

		// if a file was already upload call onDelete callback for removing via API call
		const fileIDForAPIRemove = get(files, `[${index}].id`)

		let removedFileIDs = []
		if (onDelete && fileIDForAPIRemove) {
			removedFileIDs = await onDelete(fileIDForAPIRemove)
		}
		const newFiles = isEmpty(removedFileIDs) ? remove(files, (val, i) => i != index) : remove(files, (val) => indexOf(removedFileIDs, get(val, 'id')) < 0)

		input.onChange([...newFiles])
		this.setState({
			files: newFiles
		})
	}

	onDrop = async (files) => {
		const { input, dokumentTyp } = this.props
		const loaded = 0

		this.setState({
			loaded,
			loading: true
		})

		// transform file to primitive object as prevention for redux state mutation
		const normalizeFilesPromises = map(files, async (file) => {
			const base64 = await getBase64FromFile(file)
			const dokument = base64 ? base64.split(',') : ''

			const document = {
				dataAsBase64: dokument[1],
				name: file.name,
				type: file.type
			}

			if (!isEmpty(dokumentTyp)) {
				return {
					...document,
					dokumentTyp
				}
			}
			return document
		})

		const normalizeFiles = await Promise.all(normalizeFilesPromises)
		const orderedFiles = this.orderFiles([...this.state.files, ...normalizeFiles])

		this.setState(
			{
				files: orderedFiles
			},
			() => {
				input.onChange(orderedFiles)
			}
		)
	}

	render() {
		const {
			meta: { touched, error },
			placeholder,
			labelText,
			disabled,
			t,
			multiple
		} = this.props
		const { showRemoveConfirmModal } = this.state

		let modal
		if (get(showRemoveConfirmModal, 'visible')) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Upozornenie!')}
					modalContent={t('translation:Common.Naozaj chcete súbor odstrániť?')}
					rightButton={{
						onClick: () =>
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							}),
						text: t('translation:Common.Späť'),
						color: 'blue'
					}}
					leftButton={{
						onClick: () => {
							this.removeFile(get(showRemoveConfirmModal, 'index'))
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							})
						},
						text: t('translation:Common.Odstrániť'),
						color: 'red',
						outline: true
					}}
					visible
				/>
			)
		}

		let fileCounter = null
		if (this.state.files && this.state.files.length > 0) {
			fileCounter = map(this.state.files, (file, index) => {
				const showDeleteButton = !get(file, 'id') || get(file, 'dataAsBase64')
				return (
					<div key={index} className='file-box'>
						<div>
							{get(file, 'id') ? (
								<a
									className='file'
									onClick={() => openDataUriWindow(get(file, 'id'), get(file, 'name') || get(file, 'nazov'))}
									data-type='general'
									style={{ cursor: 'pointer' }}
								>
									<span style={{ wordBreak: 'break-all' }}>{get(file, 'name') || get(file, 'nazov')}</span>
								</a>
							) : (
								<span style={{ wordBreak: 'break-all' }}>{get(file, 'name') || get(file, 'nazov')}</span>
							)}
						</div>

						<div className='actions-btn-box'>
							{showDeleteButton && (
								<a
									className='remove-file-btn'
									onClick={() =>
										this.setState({
											showRemoveConfirmModal: {
												visible: true,
												index
											}
										})
									}
								/>
							)}
						</div>
					</div>
				)
			})
		}

		return (
			<>
				{modal}
				<div className='input-wrapper upload-dropzone-wrapper'>
					{labelText && <label>{labelText}</label>}
					<div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', margin: '10px 0' }}>{fileCounter}</div>
					<Dropzone onDrop={this.onDrop} disabled={disabled} multiple={multiple}>
						{({ getRootProps, getInputProps }) => (
							<div className={cx('dropzone', { 'has-error': touched && error })} {...getRootProps()}>
								<input {...getInputProps()} />
								<p>{placeholder || 'Klikni alebo presuň súbory pre nahratie'}</p>
							</div>
						)}
					</Dropzone>
					<div className='text-danger'>{touched ? error : ''}</div>
				</div>
			</>
		)
	}
}

export default compose(withTranslation('components'))(DropZoneField)
